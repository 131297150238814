<template>
  <form>
    <input-type
      v-for="field in schema"
      :key="field.name"
      v-model="value[field.name]"
      v-validate="{ rules: field.rules }"
      :data-vv-value-path="'value'"
      :data-vv-as="field.label2 ? field.label2 : field.label"
      :error-message="vErrors.first(field.name)"
      :label="field.label"
      :name="field.name"
      :required="field.rules.required"
    />
    <select-type
      v-model="value['voivodeship']"
      v-validate="{ rules: { required: true }}"
      name="voivodeship"
      label="Województwo"
      :required="true"
      :options="options"
      :empty-first="true"
      data-vv-as="Województwo"
      :error-message="vErrors.first('voivodeship')"
    />
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'
import SelectType from '../../../../../share/form/type/SelectType'
import VoivodeshipMixin from '../../../../share/voivodeship/VoivodeshipMixin'
import Schema from './Schema'

export default {
  components: {
    InputType,
    SelectType
  },
  mixins: [
    Schema,
    FormModalValidation,
    VoivodeshipMixin
  ],
  props: {
    value: {}
  },
  data () {
    return {
      service: 'core'
    }
  }
}
</script>
